<template>
	<div class="">
		<!--本页切换列表-->
		<div v-show="!dialogFormVisible">
			<div class="tableCon" v-loading="loading_load" :element-loading-text="$t('47df8be257c59dde')">
				<div class="tableConTable">
					<div class="tableConTop">
						<el-row>
							<el-col :span="5" class="tableConTopLeft">
								<h3>
									<span class="tct_tit">{{$t('i18nn_54082523ac9409ca')}}</span>
									<el-tooltip effect="dark" :content="$t('i18nn_90b7f7d6846dfdce')" placement="top">
										<el-button type="warning" circle icon="el-icon-download" size="mini"
											@click="exportExcel()"></el-button>
									</el-tooltip>
								</h3>
							</el-col>
							<el-col :span="19" class="tableConTopRig">
								<!-- <el-button  @click="updatePartnerDataAction($event,$t('4e9fc68608c60999'))" type="danger" size="small" icon="el-icon-close">{{$t('i18nn_4f62f8d07bb4054f')}}</el-button> -->
								<!-- <span v-if="!isSel"> -->
								<!-- <el-button type="primary" icon="el-icon-plus" size="small" @click="openDioalog(null, $t('i18nn_831ab31568a78285'))">{{$t('i18nn_831ab31568a78285')}}</el-button> -->
								<!-- </span> -->
								<!-- <el-button @click="completeAction($event,null)" type="danger" size="small" icon="el-icon-finished">{{$t('25e03120b83ee4ed')}}</el-button> -->
								<!--右边容器-->
								<!-- <slot name="topRight"></slot> -->
								<el-button type="success" circle icon="el-icon-refresh" size="small"
									@click="initData()"></el-button>
							</el-col>
						</el-row>
					</div>

					<div>
						<div class="filterCon" style="">
							<!-- <el-row type="flex" justify="start"> -->
							<!-- <el-col :span="24"> -->
							<ul class="filterConList">

								<li>
									<span class="red-required">{{ $t('hytxs0000060') }}</span>
									<cusSelFuzzy @changeData="changCus"></cusSelFuzzy>


								</li>
								<li>
									<span>{{$t('i18nn_80d0adba3c341069')}}</span>
									<el-select style="width: 100px;" filterable clearable
										v-model="filterData.wh_account_income_type"
										:placeholder="$t('2ad108ab2c560530')" size="small" @change="initData()">
										<el-option v-for="item in selectOption.wh_account_income_type" :key="item.code"
											:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
											<div class="sel_option_s1" style="">
												<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
												<span class="sel_option_code" style="">{{ item.code }}</span>
											</div>
										</el-option>
									</el-select>
								</li>
								<li>
									<span>{{$t('i18nn_6ab4144b540a8a6a')}}</span>
									<el-select style="width: 100px;" filterable clearable
										v-model="filterData.wh_account_change_type"
										:placeholder="$t('2ad108ab2c560530')" size="small" @change="initData()">
										<el-option v-for="item in selectOption.wh_account_change_type" :key="item.code"
											:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
											<div class="sel_option_s1" style="">
												<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
												<span class="sel_option_code" style="">{{ item.code }}</span>
											</div>
										</el-option>
									</el-select>
								</li>

								<li>
									<span>{{$t('46c3f6e0f657e7a3')}}</span>
									<el-select style="width: 100px;" filterable clearable
										v-model="filterData.wh_fee_type" :placeholder="$t('2ad108ab2c560530')"
										size="small" @change="initData()">
										<el-option v-for="item in selectOption.wh_fee_type" :key="item.code"
											:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
											<div class="sel_option_s1" style="">
												<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
												<span class="sel_option_code" style="">{{ item.code }}</span>
											</div>
										</el-option>
									</el-select>
								</li>
								<li>
									<span>{{$t('i18nn_1466b1690d2ae404')}}</span>
									<el-date-picker v-model="filterData.daterange" size="small" type="daterange"
										align="right" :clearable="true" unlink-panels range-separator="-"
										start-placeholder="start date" end-placeholder="end date"
										:picker-options="pickerOptions" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
										@change="initData()"></el-date-picker>
								</li>

								<!-- <li v-if="tableData.length<=0">
											<el-button icon="el-icon-s-promotion" size="small" type="primary" @click="createPrepaidAccount()">{{$t('i18nn_a9320232a40b10dc')}}</el-button>
										</li> -->
								<!-- <li>
                      <span>{{$t('i18nn_49504f74aad443ce')}}</span>
                      <el-input
                        type="text"
                        v-model="filterData.accountName"
                        size="small"
                        clearable
                        @keyup.enter.native="initData()"
                        maxlength="50"
                        :placeholder="$t('hytxs0000001')"
                        style="width: 180px;"
                      />
                    </li> -->
								<!-- <li><el-button icon="el-icon-search" size="small" type="primary" @click="serAll()">{{$t('i18nn_10d660564497918d')}}</el-button></li> -->

								<!-- <li><el-button icon="el-icon-search" size="small" type="primary" @click="initData()">{{$t('1e7246dd6ccc5539')}}</el-button></li> -->
							</ul>
							<!-- <el-select filterable clearable size="small" v-model="queryParamObj.feeType" :placeholder="$t('5a9aefbc03c778f7')" style="width: 150px;">
                    <el-option v-for="item in selectOption.matching_fee" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code"><div class="sel_option_s1" style="">
				<span class="sel_option_name">{{ item.companyName }}</span>
				<span class="sel_option_code" style="">{{ item.cusNo }}</span>
			</div></el-option>             </el-select> -->
							<!-- </el-col> -->
							<!-- <el-col :span="5">
                  <span>{{$t('i18nn_b9cf6fa157d27973')}}</span>
                  <el-select filterable clearable size="small" v-model="queryParamObj.goodsType" :placeholder="$t('i18nn_b9cf6fa157d27973')" style="width: 120px;">
                    <el-option v-for="item in selectOption.goods_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
                    </el-option>
                  </el-select>
                </el-col> -->
							<!-- <el-col :span="2"><el-button type="primary" @click="serPageData" size="small">{{$t('9eefb7335988c499')}}</el-button></el-col> -->
							<!-- <el-col :span="6" class="tableConTopRig">

                </el-col> -->
							<!-- </el-row> -->
						</div>
					</div>

					<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
						:height="$store.state.tableMaxHeight2" @row-click="handleCurrentChange"
						@selection-change="handleSelectionChange" style="width: 100%" size="small">
						<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
							:label="$t('7b1c2b1adc920d9c')"></el-table-column>
						<!-- <el-table-column type="selection" fixed="left" align="center" width="50" label-class-name="nodra"></el-table-column> -->

						<!-- <el-table-column :label="$t('i18nn_8bdf7efaeff69d77')"> -->
						<!-- <el-table-column prop="plStatusName" :label="$t('6cdece641436d7ab')"></el-table-column> -->

						<!-- <el-table-column prop="opNo" :label="$t('i18nn_a51a59d19387d567')"></el-table-column> -->
						<!-- <el-table-column prop="opNo" :label="$t('i18nn_a51a59d19387d567')">
              <template slot-scope="scope">
                  <router-link :to="{name:'WhFinanceDetList',query:{opNo:scope.row.opNo}}">{{scope.row.opNo}}</router-link>
             </template>
            </el-table-column> -->
						<el-table-column prop="incomeType" :label="$t('i18nn_80d0adba3c341069')">
							<template slot-scope="scope">
								<el-tag type="primary" v-if="'1'==scope.row.incomeType">{{scope.row.incomeTypeName}}
								</el-tag>
								<el-tag type="warning" v-else-if="'2'==scope.row.incomeType">
									{{scope.row.incomeTypeName}}</el-tag>
								<el-tag type="info" v-else>{{scope.row.incomeTypeName}}</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="account" :label="$t('i18nn_169a0780408f1cce')"></el-table-column>


						<el-table-column prop="changeTypeName" :label="$t('i18nn_6ab4144b540a8a6a')"></el-table-column>

						<el-table-column prop="changeAmt" :label="$t('i18nn_5706df6392c2b9ef')"></el-table-column>

						<el-table-column prop="feeTypeName" :label="$t('46c3f6e0f657e7a3')"></el-table-column>


						<el-table-column prop="remark" :label="$t('15b3627faddccb1d')">
							<template slot-scope="scope">
								<el-popover placement="top"
							    trigger="hover"
							    ><div class="pre-text">{{ scope.row.remark }}</div>
							    <span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
							  </el-popover>
							</template>
						</el-table-column>

						<el-table-column prop="userId" :label="'UserId'"></el-table-column>


						<el-table-column prop="feeTime" :label="$t('i18nn_1466b1690d2ae404')"></el-table-column>

						<!-- <el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')"></el-table-column> -->

						<!-- <el-table-column :label="$t('93f5ca01b006206c')" width="150px" fixed="right"> -->
						<!-- <template slot-scope="scope"> -->
						<!-- <el-button @click="openEdit($event,scope.row)" type="warning" size="mini" icon="el-icon-bank-card">{{$t('i18nn_831ab31568a78285')}}</el-button> -->

						<!-- <el-button type="danger" size="mini" icon="el-icon-minus" @click="delAction($event, scope.row)" v-if="'2'!=scope.row.opStatus">{{$t('e33c9b93c36fd250')}}</el-button> -->

						<!-- <el-button @click="completeAction($event, scope.row)" type="success" size="mini" icon="el-icon-finished" v-if="'2'!=scope.row.opStatus">{{$t('25e03120b83ee4ed')}}</el-button> -->
						<!-- </template> -->
						<!-- </el-table-column> -->
					</el-table>
				</div>
				<div class="tableConPagination">
					<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
				</div>
			</div>
		</div>
		<!-- 修改弹窗 -->
		<!--本页切换，新增、修改、查看等-->
		<!-- <hyPageShow :visible="dialogFormVisible" @updateVisible="updateVisible" :title="$t('i18nn_0f864f84a5e810d6')" :subtitle="dialogFormVisibleMsg">
       <div slot="main" class="detEditOpenCon">
        <el-form ref="form" :rules="formRules" :model="form" label-width="100px" style="width: 1000px;" v-loading="loading" :element-loading-text="$t('44bd089554515c4d')">
         
          <el-form-item :label="$t('i18nn_c38333badc226309')" prop="">
            <el-tag>{{form.accountName}}</el-tag>
            <el-tag type="info">{{form.userId}}</el-tag>
           
          </el-form-item>

          <el-form-item :label="$t('i18nn_a06e84801d6582be')" prop="">
						<el-input-number v-model="form.useAmt" :label="$t('i18nn_01571203859d6b8c')"></el-input-number>
					</el-form-item>
         
        </el-form>
        <div>
          <el-button type="primary" style="width: 220px;" @click="submitForm('form')">{{$t('i18nn_d552aa9f1c14363f')}}</el-button>
        </div>
      </div>
    </hyPageShow> -->
		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData"
			:pagination="pagination" :excelName="excelName" :excelOption="excelOption"
			:expHttpUrl="$urlConfig.WhAccountRecordPageList" :expHttpFilter="pageFilterData()"></whExcelCustom>
	</div>
</template>
<script>
	import {
		getDicData
	} from '@/axios/common.js';
	// import excelUtilsNew from '@/utils/excelUtilsNew.js';
	import cusSelFuzzy from '@/components/WarehouseCenter2/components/cusSelFuzzy.vue';
	import whExcelCustom from '@/components/WarehouseCenter2/components/whExcelCustom.vue';
	export default {
		components: {
			// WhCustomer
			cusSelFuzzy,
			whExcelCustom
		},
		data() {
			return {

				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: "",
				excelOption: {},

				pickerOptions: this.$PickerDate.pickerOptions_1(),

				dialogFormVisible: false,
				dialogFormVisibleMsg: '',

				selectOption: {
					wh_fee_type: [],
					wh_account_income_type: [],
					wh_account_change_type: []
				},

				//表格数据
				//loading,表格数据
				loading: false,
				loading_load: false,
				//表格数据
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				//详情数据
				loading_det: false,

				// form: {
				//   id: null,

				//   userId: '', //标题',
				//   useAmt: '', //this.$t('i18nn_ab6ea90b9164b20a'),
				//   remark:'',
				// },
				// invalidDateForm: {
				//   "confId": null,
				//   "invalidDate": "", //失效日期
				//   effectiveDate: "", //生效时间
				//   confName: "", //仅做显示
				// },
				//   formRules: {
				//     userId: [{ required: true, message: this.$t('5a9aefbc03c778f7'), trigger: 'blur' }],
				// useAmt: [{ required: true, message: this.$t('5a9aefbc03c778f7'), trigger: 'change' }]
				//   },
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

					// plStatus:'',
					userId: '',
					userName: '',
					wh_fee_type: '',
					wh_account_income_type: '',
					wh_account_change_type: '',
					// daterange: [this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 3600 * 1000 * 24 * 30), this.$Utils.fomatterDate_YYYYMMdd(new Date())],
					daterange: [],
					// accountName: ''
				}
			};
		},
		activated() {
			this.initData();
		},
		//创建时
		created() {
			// this.initData();
		},
		//编译挂载前
		mounted() {
			// console.log("mounted");
			// this.getDicData();
			//数据字典
			getDicData(['wh_fee_type', 'wh_account_income_type',
						'wh_account_change_type'],
				(data)=>{
					this.selectOption.wh_fee_type = data['wh_fee_type'];
					this.selectOption.wh_account_income_type = data['wh_account_income_type'];
					this.selectOption.wh_account_change_type = data['wh_account_change_type'];
			});
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				this.currentSel = {};
				this.multipleSelection = [];
				this.getPageData();

			},

			changCus(data) {
				console.log('changCus', data);
				// this.dialogSelVisible = false;
				this.filterData.userId = data.userId;
				this.filterData.userName = data.companyName;
				this.initData();
			},
			//导出 excel
			exportExcel() {

				let columns = [{
						title: this.$t('i18nn_80d0adba3c341069'),
						key: 'incomeTypeName'
					},
					{
						title: this.$t('i18nn_169a0780408f1cce'),
						key: 'account'
					},
					{
						title: this.$t('i18nn_6ab4144b540a8a6a'),
						key: 'changeTypeName'
					},
					{
						title: this.$t('i18nn_5706df6392c2b9ef'),
						key: 'changeAmt'
					},
					{
						title: this.$t('46c3f6e0f657e7a3'),
						key: 'feeTypeName'
					},
					{
						title: this.$t('15b3627faddccb1d'),
						key: 'remark',
					},
					{
						title: this.$t('i18nn_1466b1690d2ae404'),
						key: 'feeTime',
					},
					{
						title: 'userId',
						key: 'userId',
					},
				];
				// let Data = this.tableData;

				this.expExcelData = this.tableData;
				this.excelHead = columns;
				this.excelName = 'WhAccountRec';
				this.excelOption = {
					height: 20
				};
				this.excelOpenTime = new Date().getTime();

				// excelUtilsNew.exportExcel(columns, Data, 'WhAccountRec',{height:20});
			},
			//生成预付账户
			// createPrepaidAccount(){
			// 	if(this.filterData.userId){
			// 		this.createPrepaidAccountData(this.filterData.userId);
			// 	} else {
			// 		this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
			// 	}
			// },
			// createPrepaidAccountData(userId){
			// 	// let formData = Object.assign({}, this.form);
			// 	// formData.id = null;
			// 	this.postData(this.$urlConfig.WhAccountAdd+'/'+userId, {}, () => {
			// 	  // this.dialogFormVisible = false;
			// 	  this.getPageData();
			// 	  // this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
			// 	  //   type: 'success',
			// 	  //   
			// 	  // });
			// 	  this.$message.success('生成预付账户成功！');
			// 	});
			// },
			//状态
			// formatterEnable(row, column){
			//   if('1'===row.enable){
			//     return this.$t('i18nn_889b3ef0590d9f54');
			//   } else if('0'===row.enable){
			//     return this.$t('4e9fc68608c60999');
			//   } else {
			//     return row.enable;
			//   }
			// },
			//查询数据
			// serPageData() {
			//   this.pagination.current_page = 1;
			//   this.getPageData();
			// },
			// formatterDate(row, column) {
			//   if (row.invalidDate == null) {
			//     return '';
			//   }
			//   return row.invalidDate.substring(0, 10);
			// },
			//分页的筛选项数据
			pageFilterData() {
				let startTime = '';
				let endTime = '';
				if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
					startTime = this.filterData.daterange[0];
					endTime = this.filterData.daterange[1];
				} else {
					// this.$message.warning(this.$t('i18nn_cffd7c9783c11047'));
				}

				return {
					// plStatus: this.filterData.plStatus? this.filterData.plStatus:null,
					userId: this.filterData.userId ? this.filterData.userId : null,
					"feeType": this.filterData.wh_fee_type ? this.filterData.wh_fee_type : null,
					incomeType: this.filterData.wh_account_income_type ? this.filterData.wh_account_income_type : null,
					changeType: this.filterData.wh_account_change_type ? this.filterData.wh_account_change_type : null,

					startFeeTime: startTime ? startTime : null,
					endFeeTime: endTime ? endTime : null,
					// "goodsType": this.queryParamObj.goodsType
				};
			},

			//请求分页数据
			getPageData() {

				let filterData = Object.assign({
					offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
					limit: this.pagination.page_size, //当前页显示数目
				}, this.pageFilterData())

				this.loading_load = true;

				this.$http
					.put(this.$urlConfig.WhAccountRecordPageList, filterData)
					.then(({
						data
					}) => {
						
						this.loading_load = false;
						if (200 == data.code) {
							//表格显示数据
							this.tableData = data.rows;
							//当前数据总条数
							this.pagination.total = parseInt(data.total);
							//当前页数
							// this.pagination.current_page = parseInt(data.current);
							//当前页条数
							// this.pagination.page_size = parseInt(data.size);
							// this.$message.success('商家账单列表，请求成功');
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			},
			//打开二级分配弹窗
			// openDetModal(row) {
			//   this.dialogDetVisible = true;
			//   this.detData = [];
			//   this.getDetData(row.id);
			// },
			// getDetListData(opNo) {
			//   this.loading_det = true;
			//   this.$http.put(this.$urlConfig.WhFinanceRecPageList, {
			//       offset: 0,
			//       limit: 100,
			//       "opNo": opNo
			//     })
			//     .then(({ data }) => {
			//       this.loading_det = false;
			//       if (200 == data.code) {
			//         //表格显示数据
			//         // this.detData = data.rows;
			//         this.form.finRecords = data.rows;
			//       } else {
			//         this.$message.warning(data.msg ? data.msg : this.$t('i18nn_9c0ace2b5d32f74c'));
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       this.$message.error('请求数据有误！');
			//       this.loading_det = false;
			//     });
			// },
			//新增
			// addLe2() {
			//   this.form.finRecords.push({
			//     finName: '',
			//     finAmt: '0',
			//     remark:''
			//   });
			// },
			// //删除
			// delLe2(index) {
			//   this.form.finRecords.splice(index, 1);
			// },
			//弹窗更新
			updateVisible(val) {
				this.dialogFormVisible = val;
			},
			// openDioalog(formParm, msg) {
			//   console.log(formParm);
			//   this.dialogFormVisibleMsg = msg;
			//   this.dialogFormVisible = true;
			//   // let form = Object.assign({}, formParm);
			//   // console.log(form);
			//   // 重置
			//   this.resetForm('form');
			//   if (null === formParm) {
			//     //新增
			//     // 重置
			//     // this.resetForm('form');
			//   } else {
			//     //修改
			//     // 重置
			//     // this.resetForm('form');
			//     let form = Object.assign({}, formParm);
			//     this.form = Object.assign({}, form);
			//     // this.getDetListData(form.opNo);
			//   }
			// },
			//打开编辑
			// openEdit(event,row){
			//   event.stopPropagation();
			//   this.openDioalog(row, this.$t('i18nn_831ab31568a78285'))
			// },

			//删除，分成模板
			// deleteTem() {
			//   if (this.currentSel.confId == null) {
			//     this.$alert('请选择需要删除的数据!', this.$t('cc62f4bf31d661e3'));
			//     return;
			//   }

			//   // this.invalidDateForm.confId = this.currentSel.confId;
			//   // let formData = Object.assign({}, this.invalidDateForm);
			//   // // formData.id = this.currentSel.id;
			//   // this.postData(this.$urlConfig.HySettleConfAllocationOff, formData);
			// },

			// openDioalogInvalidDate() {
			//   console.log(this.currentSel);
			//   if (this.currentSel.confId == null) {
			//     this.$alert('请选择需要注销的数据!', this.$t('cc62f4bf31d661e3'));
			//     return;
			//   }
			//   this.dialogFormInvalidDate = true;
			//   let form = Object.assign({}, this.currentSel);
			//   // console.log(form);

			//   // 重置
			//   this.resetForm('invalidDateForm');
			//   this.invalidDateForm.confId = form.confId;
			//   this.invalidDateForm.confName = form.confName;
			//   this.invalidDateForm.effectiveDate = form.effectiveDate;
			//   // if(!!form.invalidDate){
			//   //   this.invalidDateForm.invalidDate = form.invalidDate;
			//   // } else {
			//   //   this.invalidDateForm.invalidDate = "";
			//   // }
			// },
			// resetForm(formName) {
			//   console.log(formName);
			//   this[formName] = {};

			//   // if ('form' === formName) {
			//   //   this[formName]['finRecords'] = [];
			//   // }
			//   if (this.$refs[formName]) {
			//     this.$refs[formName].resetFields();
			//     // this.form.remark = ""; //  备注//非必填
			//   } else {
			//     console.log('this.$refs[formName]', this.$refs[formName]);
			//   }
			// },
			//编辑时，格式化list
			// fomatterConfigDetList(list) {
			//   list.forEach(function(item, index, array) {
			//     item.id = null;
			//   });
			//   return list;
			// },
			//提交信息
			// submitForm(formName, type) {
			//   //验证省市县必输入
			//   this.$refs[formName].validate(valid => {
			//     // if (true) {
			//     if (valid) {
			//       // alert('submit!');

			//       //浅拷贝、对象属性的合并
			//       // this.form = Object.assign({},form);
			//       // if ('1' === type) {
			//         //新增
			//         let formData = Object.assign({}, this.form);
			//         // formData.id = null;
			//         this.postData(this.$urlConfig.WhAccountRecharge, formData, () => {
			//           this.dialogFormVisible = false;
			//           this.getPageData();
			//           // this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
			//           //   type: 'success',
			//           //   
			//           // });
			//           this.$message.success('充值成功！');
			//         });
			//     } else {
			//       console.log('error submit!!');
			//       this.$alert(this.$t('hytxs0000031'), this.$t('cc62f4bf31d661e3'), {
			//         type: 'warning',

			//       });
			//       return false;
			//     }
			//   });
			// },
			// getDicData() {
			//   this.$http
			//     .get(this.$urlConfig.HyMatchintFeeConfQueryDic)
			//     .then(({ data }) => {
			//       console.log(data);
			//       if (200 == data.code) {
			//         this.selectOption = data.data;
			//       } else {
			//         console.log('下拉列表数据请求失败!');
			//       }
			//       this.loading_load = false;
			//     })
			//     .catch(error => {
			//       console.log(error);
			//       console.log('更新特定数据，请求失败');
			//       this.loading_load = false;
			//     });
			// },
			// handleCurrentChange(val) {
			//   console.log('handleCurrentChange', val);
			//   // this.tableData.map(item => {
			//   //   if (item.confId === val.confId) {
			//   //     item.selected = true;
			//   //   } else {
			//   //     item.selected = false;
			//   //   }
			//   // });
			//   // this.currentSel = val;
			//   // this.$refs.multipleTable.toggleRowSelection(this.tableData3[2],true);
			// },
			//选择行
			handleCurrentChange(row, event, column) {
				// this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
			},
			//多选
			handleSelectionChange(val) {
				console.log(val);
				// this.multipleSelection = val;
			},
			//删除
			// delAction(event, row) {
			//         this.$confirm('确定删除吗?', this.$t('daaaeb1b7b22b126'), {
			//           // 
			//           // 
			//           type: 'warning'
			//         }).then(() => {
			//           // this.$message({
			//           //   type: 'success',
			//           //   message: '删除成功!'
			//           // });
			//           this.delDataAction(event, row);
			//         }).catch(() => {
			//           // this.$message({
			//           //   type: 'info',
			//           //   message: this.$t('i18nn_2e58cb9b52e2a214')
			//           // });
			//         });
			//       },
			//删除
			// delDataAction(event, row) {
			//   event.stopPropagation();
			//   console.log('delAction', row);
			//   let parm = [];
			//   parm = {};
			//   this.postData(this.$urlConfig.WhFinanceDel + '/' + row.id, parm, () => {
			//     this.initData();
			//     //  this.$alert('恭喜,提交成功！', this.$t('cc62f4bf31d661e3'), {
			//     //   type: 'success',
			//     //   
			//     // });
			//     this.$message.success(this.$t('9f30371831a98237'));
			//   });
			// },


			//提交数据
			postData(url, formData, callback) {
				// let _this = this;
				this.loading = true;

				this.$http
					.put(url, formData)
					.then(({
						data
					}) => {
						console.log(this.$t('i18nn_bc868e024b80d2e3'));
						console.log(data);
						
						this.loading = false;
						if (200 == data.code) {
							callback();
						} else {
							if (!data.msg) {
								data.msg = this.$t('dbe331ab679cd67f');
							}
							this.$alert(data.msg, this.$t('cc62f4bf31d661e3'), {
								type: 'warning',

							});
						}
					})
					.catch(error => {
						console.log(error);
						console.log(this.$t('i18nn_a7d2e953195a5588'));
						this.loading = false;
						this.$alert(this.$t('33bae59bb784a109'), this.$t('cc62f4bf31d661e3'), {
							type: 'warning',

						});
					});
			},
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_fee_type', 'wh_account_income_type',
			// 			'wh_account_change_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_fee_type = data.data['wh_fee_type'];
			// 				this.selectOption.wh_account_income_type = data.data['wh_account_income_type'];
			// 				this.selectOption.wh_account_change_type = data.data['wh_account_change_type'];
			// 				// this.selectOption.wh_op_status = data.data['wh_op_status'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('hytxs0000032');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('hytxs0000033'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-table__body {
	//   .el-input {
	//     input {
	//       padding-left: 5px;
	//       padding-right: 5px;
	//     }
	//   }
	//   // .el-input-number {
	//   //   width: 80px;
	//   //   .el-input__inner {
	//   //     text-align: left;
	//   //   }
	//   // }
	// }
</style>
